import clsx from "clsx";
import React from "react";
import useResizeObserver from "use-resize-observer";
import { useSidebarMenuRootContext } from "../SidebarMenuRoot/SidebarMenuRootContext";
import "./sidebarMenuContent.scss";

interface ISidebarMenuContentProps {
    children: React.ReactNode;

    className?: string;
    style?: React.CSSProperties;
}

export function SidebarMenuContent(props: ISidebarMenuContentProps) {
    const { show } = useSidebarMenuRootContext();

    const { ref: measureRef, height = 0 } = useResizeObserver();

    const animatedStyles: React.CSSProperties = {
        height: show ? `calc(${height}px + 1rem)` : 0,
        opacity: show ? 1 : 0,
        pointerEvents: show ? "all" : "none",
        transform: show ? "translateY(0)" : "translateY(-0.75rem)",
    };

    return (
        <div
            className={clsx(
                "sidebar-menu-content",
                {
                    "sidebar-menu-content--open": show,
                },
                props.className
            )}
            style={{ ...props.style, ...animatedStyles }}>
            <div ref={measureRef} className="sidebar-menu-content__list">
                {props.children}
            </div>
        </div>
    );
}

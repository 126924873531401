import * as screenSizePresets from "../../constants/others";
import { useMediaQuery } from "./useMediaQuery";

/**
 * @param size Size in pixels or a preset
 * @returns
 */
export function useScreenIsAtLeast(size: ScreenSize) {
    return useMediaQuery(getMediaQuery(size));
}

type ScreenSizePreset = "sm" | "md" | "lg" | "xl" | "xxl";
type ScreenSize = number | ScreenSizePreset;

const SIZE_PRESETS: Record<ScreenSizePreset, number> = {
    sm: screenSizePresets.smallScreenSize,
    md: screenSizePresets.mediumScreenSize,
    lg: screenSizePresets.largeScreenSize,
    xl: screenSizePresets.largestScreenSize,
    xxl: screenSizePresets.xxlScreenSize,
};

function getMediaQuery(size: ScreenSize): string {
    if (typeof size === "number") {
        return `(min-width: ${size}px)`;
    }

    const sizePx = SIZE_PRESETS[size];
    return `(min-width: ${sizePx}px)`;
}

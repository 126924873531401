import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React from "react";
import { useSidebarMenuRootContext } from "../SidebarMenuRoot/SidebarMenuRootContext";
import "./sidebarMenuNode.scss";

interface ISidebarMenuNodeProps {
    value: string;

    icon?: IconProp;
    children: React.ReactNode;
}

export function SidebarMenuNode(props: ISidebarMenuNodeProps) {
    const { value: activeValue, setValue: setActiveValue } = useSidebarMenuRootContext();

    const handleClick = () => {
        setActiveValue(props.value);
    };

    const isActive = activeValue === props.value;

    return (
        <div
            className={clsx("sidebar-menu-node", {
                "sidebar-menu-node--active": isActive,
            })}
            onClick={handleClick}>
            {props.icon && <FontAwesomeIcon className="sidebar-menu-node__icon" icon={props.icon} />}

            {props.children}
        </div>
    );
}

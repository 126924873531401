import React, { useState } from "react";
import { largestScreenSize } from "../../../constants/others";
import { useScreenIsAtLeast } from "../../../util/customHooks/useScreenIsAtLeast";
import { SidebarMenuRootContext } from "./SidebarMenuRootContext";
import "./sidebarMenuRoot.scss";
import clsx from "clsx";

interface ISidebarMenuRootProps {
    value: string | null;
    onValueChange: (value: string) => void;

    children: React.ReactNode;
    className?: string;
}

export function SidebarMenuRoot(props: ISidebarMenuRootProps) {
    const [show, setShow] = useState(true);
    const isXlUp = useScreenIsAtLeast(largestScreenSize);

    if (isXlUp && !show) {
        setShow(true);
    }

    return (
        <SidebarMenuRootContext.Provider value={{ value: props.value, setValue: props.onValueChange, show, setShow }}>
            <div className={clsx("sidebar-menu-root", props.className)}>{props.children}</div>
        </SidebarMenuRootContext.Provider>
    );
}

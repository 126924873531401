import { createContext, useContext } from "react";

interface ISidebarMenuGroupContextProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const SidebarMenuGroupContext = createContext<ISidebarMenuGroupContextProps | null>(null);

export function useSidebarMenuGroupContext() {
    const ctx = useContext(SidebarMenuGroupContext);
    if (!ctx) {
        throw new Error("useSidebarMenuGroupContext must be used inside a <SidebarMenuGroupContext.Provider>");
    }

    return ctx;
}

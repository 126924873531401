import clsx from "clsx";
import React from "react";
import useResizeObserver from "use-resize-observer";
import { useSidebarMenuGroupContext } from "../SidebarMenuGroupContext";
import "./sidebarMenuGroupList.scss";

interface ISidebarMenuGroupListProps {
    children: React.ReactNode;
}

export function SidebarMenuGroupList(props: ISidebarMenuGroupListProps) {
    const { open } = useSidebarMenuGroupContext();

    const { ref: measureRef, height = 0 } = useResizeObserver();
    const animatedStyles: React.CSSProperties = {
        opacity: open ? 1 : 0,
        pointerEvents: open ? "all" : "none",
        marginTop: open ? "-1rem" : 0,
        paddingTop: open ? "1rem" : 0,
        // 2rem: paddingTop + vertical padding of .sidebar-menu-group-list__inner
        // 2px: top/bottom border
        height: open ? `calc(${height}px + 2rem + 2px)` : 0,
    };

    return (
        <div
            className={clsx("sidebar-menu-group-list", {
                "sidebar-menu-group-list--open": open,
            })}
            style={animatedStyles}>
            <div ref={measureRef} className="sidebar-menu-group-list__inner">
                {props.children}
            </div>
        </div>
    );
}

import React, { useEffect, useRef, useState } from "react";
import { useSidebarMenuRootContext } from "../SidebarMenuRoot/SidebarMenuRootContext";
import { SidebarMenuGroupContext } from "./SidebarMenuGroupContext";

interface ISidebarMenuGroupProps {
    children: React.ReactNode;
}

export function SidebarMenuGroup(props: ISidebarMenuGroupProps) {
    const { value, setValue } = useSidebarMenuRootContext();

    const ref = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const items = getItems(ref.current);
        const values = items.map(getItemValue);

        setOpen(values.includes(value));
    }, [value, setOpen]);

    const handleOpenChange = (open: boolean) => {
        setOpen(open);

        const items = getItems(ref.current);
        if (items.length === 0) {
            return;
        }

        const values = items.map(getItemValue);
        if (values.includes(value)) {
            return;
        }

        const nextValue = getItemValue(items[0]);
        if (!nextValue) {
            return;
        }

        setValue(nextValue);
    };

    return (
        <SidebarMenuGroupContext.Provider value={{ open, setOpen: handleOpenChange }}>
            <div ref={ref} className="sidebar-menu-group">
                {props.children}
            </div>
        </SidebarMenuGroupContext.Provider>
    );
}

function getItems(anchor: HTMLElement | null): HTMLElement[] {
    if (!anchor) {
        return [];
    }

    return Array.from(anchor.querySelectorAll("[data-group-item-value]")) as HTMLElement[];
}

function getItemValue(item: HTMLElement): string | null {
    return item.dataset["groupItemValue"] ?? null;
}

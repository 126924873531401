import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useSidebarMenuRootContext } from "../SidebarMenuRoot/SidebarMenuRootContext";
import "./sidebarMenuTrigger.scss";

export function SidebarMenuTrigger() {
    const { show, setShow } = useSidebarMenuRootContext();

    const handleClick = () => {
        setShow(!show);
    };

    return (
        <button
            onClick={handleClick}
            className={clsx("sidebar-menu-trigger", {
                "sidebar-menu-trigger--open": show,
            })}>
            <FontAwesomeIcon icon={faList} />
            Navigation {show ? "schließen" : "öffnen"}
        </button>
    );
}

import clsx from "clsx";
import React from "react";
import { useSidebarMenuRootContext } from "../../SidebarMenuRoot/SidebarMenuRootContext";
import "./sidebarMenuGroupItem.scss";

interface ISidebarMenuGroupItemProps {
    value: string;

    children: React.ReactNode;
}

export function SidebarMenuGroupItem(props: ISidebarMenuGroupItemProps) {
    const { value: activeValue, setValue: setActiveValue } = useSidebarMenuRootContext();

    const handleClick = () => {
        setActiveValue(props.value);
    };

    const isActive = activeValue === props.value;

    return (
        <div
            data-group-item-value={props.value}
            className={clsx("sidebar-menu-group-item", {
                "sidebar-menu-group-item--active": isActive,
            })}
            onClick={handleClick}>
            {props.children}
        </div>
    );
}

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React from "react";
import { useSidebarMenuGroupContext } from "../SidebarMenuGroupContext";
import "./sidebarMenuGroupHeader.scss";

interface ISidebarMenuGroupHeaderProps {
    icon?: IconProp;
    children: React.ReactNode;
}

export function SidebarMenuGroupHeader(props: ISidebarMenuGroupHeaderProps) {
    const { open, setOpen } = useSidebarMenuGroupContext();

    const handleClick = () => {
        setOpen(true);
    };

    return (
        <div className="sidebar-menu-group-header" onClick={handleClick}>
            <div
                className={clsx("sidebar-menu-group-header__content", {
                    "sidebar-menu-group-header__content--open": open,
                })}>
                {props.icon && <FontAwesomeIcon className="sidebar-menu-group-header__icon" icon={props.icon} />}

                {props.children}

                <FontAwesomeIcon
                    className={clsx("sidebar-menu-group-header__caret", {
                        "sidebar-menu-group-header__caret--rotate": open,
                    })}
                    icon={faChevronRight}
                />
            </div>
        </div>
    );
}

import { createContext, useContext } from "react";

interface ISidebarMenuRootContextProps {
    value: string | null;
    setValue: (value: string) => void;
    //
    show: boolean;
    setShow: (show: boolean) => void;
}

export const SidebarMenuRootContext = createContext<ISidebarMenuRootContextProps | null>(null);

export function useSidebarMenuRootContext() {
    const ctx = useContext(SidebarMenuRootContext);
    if (!ctx) {
        throw new Error("useSidebarMenuRootContext must be used inside a <SidebarMenuRootContext.Provider>");
    }

    return ctx;
}
